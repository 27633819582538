import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper from '../components/wrapper'
import Article from "../components/article"
import Section from '../components/section'
import Callout from '../components/callout'

const CALLOUT_POSTS_COUNT = 3

const Blog = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  const firstPosts = posts.slice(0, CALLOUT_POSTS_COUNT)

  const renderPost = (post) => {
    const { path, title, description, date, cover_image: coverImage } = post.node.frontmatter

    return <Section path={path} title={title} description={description} date={date} image={coverImage} />
  }

  return (
    <Layout title="Blog">
      <SEO title="Serverless IDE Blog" />
      <Wrapper>
        <Article>
          {firstPosts.map(renderPost)}
        </Article>
      </Wrapper>
      {/* <Callout title="Subscribe to our newsletter" description="Subscribe to our newsletter to know first about new features and blog posts" link="https://pavel207.typeform.com/to/jIICkA" /> */}
      <Wrapper>
        <Article>
          {posts.slice(CALLOUT_POSTS_COUNT).map(renderPost)}
        </Article>
      </Wrapper>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            description
            tags
            cover_image
          }
        }
      }
    }
  }
`;
